import React from 'react'
import "./Chat.css";
function Chat() {
    return (
        <div>
            <div id="whatsapp-widget-timelines" class="ww-left ww-normal">
  <a href="https://wa.me/917000379913/" class="ww-text">Discuss a project or just want to say hi?</a>
  <div class="ww-icon">
    <a href="https://wa.me/917000379913/">
    <img  alt="whatsapp" src="https://img.icons8.com/color/48/000000/whatsapp--v4.png"/>
    </a>
 
  </div>
</div>
        </div>
    )
}

export default Chat
